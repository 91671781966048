<template>
	<div class="orders">
		<h1>Мои заказы</h1>

		<v-row class="mt-4">
			<v-col>
				<v-btn color="primary" @click="$router.push({name:'orders-new'})">Новый заказ</v-btn>
			</v-col>
		</v-row>

		<v-row v-if="items && items.length">
			<v-col>
				<v-expansion-panels v-if="items" v-model="panels" multiple class="mt-4">
					<v-expansion-panel
							v-for="(period,i) in nonEmptyPeriods"
							:key="i">
						<v-expansion-panel-header expand-icon="mdi-menu-down">{{period.NAME}}</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-data-table v-if="items"
										  :headers="headers"
										  :items="itemsOfPeriod(period)"
										  class="mt-4"
										  hide-default-footer
										  :items-per-page="-1"
										  :item-class="itemClass"
										  @click:row="onSelectOrder">
								<template v-slot:item.DELIVERY_DATE="{ item }">
									<span v-if="item.CONFIRMED_DELIVERY_DATE">{{item.CONFIRMED_DELIVERY_DATE}}</span>
									<span v-else>{{item.DELIVERY_DATE}}</span>
								</template>
								<template v-slot:item.STATUS="{ item }">
									<v-chip :color="statusColor(item)" dark width="100px" small>{{ item.STATUS }}
									</v-chip>
								</template>
								<template v-slot:item.total="{ item }" class="d-flex justify-end">
									{{ total(item) | nf }}
								</template>
								<template v-slot:item.totalConfirmed="{ item }" class="d-flex justify-end">
									<span v-if="totalConfirmed(item)">{{ totalConfirmed(item) | nf }}</span>
								</template>
								<template v-slot:item.crud="{ item }" class="d-flex justify-end">
									<v-btn plain @click="onSelectOrder(item)">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
							</v-data-table>
							<p v-else>Нет заказов в периоде...</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
		<v-row v-else class="mt-4">
			<v-col cols="12" sm="6" md="4">
				<p>Пока нет заказов...</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
    import OrderTable from "./elements/order-tables";
    import {nf} from "@/utils/string";

    export default {

        name: "orders",
        components: {OrderTable},
        data: () => ({
            panels: [0],
            headers: [
                {text: '#', value: 'ID', sortable: false, width: "5%"},
                {text: 'Канал', align: 'left', sortable: false, value: 'channel', width: "15%"},
                {text: 'Контрагент', align: 'left', sortable: false, value: 'agent', width: "20%"},
                {text: 'Дата отгрузки', align: 'center', sortable: false, value: 'DELIVERY_DATE', width: "15%"},
                /*{text: 'Отправлен', align: 'center', sortable: false, value: 'SUBMIT_DATE', width: "15%"},*/
                {text: 'Статус', align: 'center', sortable: false, value: 'STATUS', width: "15%"},
                {text: 'Кол-во', align: 'end', value: 'total', width: "10%"},
                {text: 'Утверждено', align: 'end', value: 'totalConfirmed', width: "15%"},
                //{text: ' ', align: 'end', value: 'crud', width: "5%"},
            ],
        }),
        computed: {
            user() {
                return this.$store.state.session?.user;
            },
            items() {
                return this.$store.state.page?.OrderListController?.orders?.map(el => {
                    return {
                        ...el,
                        //"Период": this.periodName(el),
                        "plan": this.planName(el),
                        //"PERIOD": this.periodName(el),
                        "channel": this.channelName(el),
                        "agent": this.agentName(el),
                    }
                });
            },
            nonEmptyPeriods() {
                return this.periods.filter(el => {
                    return this.plans.filter(plan=>plan.PERIOD_ID===el.ID).some(plan=>this.items.some(item=>item.PLAN_ID===plan.ID));
                });
			},
            periods() {
                return this.$store.state.page?.OrderListController?.periods;
            },
            plans() {
                return this.$store.state.page?.OrderListController?.plans;
            },
            channels() {
                return this.$store.state.page?.OrderListController?.channels;
            },
            statuses() {
                return this.$store.state.page?.OrderListController?.statuses;
            }
        },
        methods: {
			itemClass(item) {
				const totalConfirmed = this.totalConfirmed(item);
				const total = this.total(item);

				/*console.log("this.isConfirmed(item)", this.isConfirmed(item));
				console.log("totalConfirmed", totalConfirmed);
				console.log("total", total);*/

				if ( this.isConfirmed(item) && totalConfirmed !== total ) return "altered";
			},
			isConfirmed(item) {
				return !!item?.CONFIRM_DATE;
			},
            itemsOfPeriod(period) {
                return this.items.filter(el => {
                    return this.plans.filter(plan=>plan.PERIOD_ID===period.ID).some(plan=>plan.ID===el.PLAN_ID);
				});
            },
            periodName(item) {
                const plan = this.plans?.find(el => el.ID === item.PLAN_ID);
                if (!plan) return null;
                const period = this.periods?.find(el => el.ID === plan.PERIOD_ID);
                return period ? period.NAME : null;
            },
            channelName(item) {
                const plan = this.plans?.find(el => el.ID === item.PLAN_ID);
                if (!plan) return null;
                const channel = this.channels?.find(el => el.ID === plan.CHANNEL_ID);
                return channel ? channel.NAME : null;
            },
            agentName(item) {
                const agent = this.user.agents?.find(el => el.ID === item.AGENT_ID);
                return agent?.NAME;
            },
            planName(item) {
                const plan = this.plans?.find(el => el.ID === item.PLAN_ID);
                return plan?.NAME;
            },
            statusColor(item) {
                return this.statuses?.find(el => el.ID === item.STATUS)?.COLOR;
            },
            onSelectOrder(item) {
                this.$router.push({name: "order", params: {id: item.ID}});
            },
            totalConfirmed(item) {
                if (!item.ITEMS_JSON || !item.ITEMS_JSON.length) return 0;
                //const prop = order.CONFIRM_DATE ? "confirmedAmount" : "amount";
                const prop = "confirmedAmount";
                return item.ITEMS_JSON.reduce((t2, item) => Number(t2) + (Number(item[prop]) || 0), 0);
            },
			total(item) {
				if (!item.ITEMS_JSON || !item.ITEMS_JSON.length) return 0;
				//const prop = order.CONFIRM_DATE ? "confirmedAmount" : "amount";
				const prop = "amount";
				return item.ITEMS_JSON.reduce((t2, item) => Number(t2) + (Number(item[prop]) || 0), 0);
			}
        }
    }
</script>

<style lang="scss">
	.orders {
		.v-data-table {
			tbody {
				tr:not(.v-row-group__header) {
					cursor: pointer;
				}

				tr.altered {
					background: $altered;
				}
			}

			.v-chip {
				width: 120px;
				justify-content: center;
			}
		}
	}
</style>