<template>
	<div class="order-tables">

		<v-row class="pt-4">
			<v-col cols="12" md="2">
				<v-btn block @click="onToggleUnfold">
					<v-icon small class="mr-2">{{unfold?'mdi-unfold-less-horizontal':'mdi-unfold-more-horizontal'}}
					</v-icon>
					{{unfold?'Свернуть':'Развернуть'}}
				</v-btn>
			</v-col>
			<template v-if="!readonly">
				<v-col cols="12" sm="6" md="2" offset-sm="0" offset-md="5">
					<v-btn block @click="reset">
						<v-icon small class="mr-2">mdi-reload</v-icon>
						Сброс
					</v-btn>
				</v-col>
				<v-col cols="4" sm="2" md="1">
					<v-btn block @click="add(-10)">-10</v-btn>
				</v-col>
				<v-col cols="4" sm="2" md="1">
					<v-btn block @click="add(10)">+10</v-btn>
				</v-col>
				<v-col cols="4" sm="2" md="1">
					<v-btn block @click="add(50)">+50</v-btn>
				</v-col>
			</template>
		</v-row>

		<v-row>
			<v-col cols="12">
				<product-tree :tree="tree"
							  :products="products"
							  :plan-items="planItems"
							  :readonly="readonly"
							  :confirmed="confirmed"
							  :name-prop="nameProp"
							  :level="0"
							  :unfold="unfold"
							  :fold="!unfold"
							  @change="onChange"/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
    import Vue from 'vue';
    import ProductTree from "@/components/elements/product-tree";

    export default {
        name: "order-table",
        props: {
            products: {
                type: Array
            },
            tree: {
                type: Array
            },
            initialItems: {
                type: Array
            },
            planItems: {
                type: Array
            },
            readonly: {
                type: Boolean
            },
			confirmed: {
				type: Boolean
			},
            nameProp: {
                type: String
            },

        },
        components: {
            ProductTree
        },
        data: () => ({
            currentChannelIndex: 0,
            unfold: false,
            fold: false,
            //items: null,
        }),
        watch: {
            planItems: {
                immediate: true,
                handler() {
                    this.initProductAmounts();
                }
            },
            initialItems: {
                immediate: true,
				//deep: true,
                handler() {
                    this.initProductAmounts();
                }
            },
			products: {
				immediate: true,
				//deep: true,
				handler() {
					this.initProductAmounts();
				}
			}
        },
        computed: {},
        methods: {
            onChange(item, section, withEmit = true) {
                this.setSectionTotal(section);

                const plainChildrenOf = (items) => {
                    if (!items) return [];
                    //console.log("STEP: ", items);
                    return items.reduce((all, item) => {
                        if (!item.children) return [...all, item];
                        return [...all, item, ...plainChildrenOf(item.children)];
                    }, []);
                };
                const plainTree = plainChildrenOf(this.tree);
                //console.log("Plain tree", plainTree);

                let parent, s = section;
                while (!!(parent = plainTree.find(el => el.children?.find(c => c.ID === s.ID)))) {
                    this.setSectionTotal(parent);
                    s = parent;
                }

                // update parents' totals
                //console.log("ON child change: "+section.NAME);
                /*this.tree?.find(parent => {
                    //console.log("\t++"+parent.NAME+"");
                    let child = parent.children?.find(s => s.ID === section.ID);
                    //console.log("\t++child: ", parent);
                    if (child) this.setSectionTotal(parent);

                    // todo update parents' parent!
                });*/

                // say to parent that we are updated
                if (withEmit) this.$emit("change", item, section);
            },
            onToggleUnfold() {
                this.unfold = !this.unfold;
            },
            reset() {
                this.products.forEach(p => {
                    p.amount = 0;

                    if (this.planItems) {
                        const actual = p.rest - p.amount;
                        Vue.set(p, "actual", actual >= 0 ? actual : 0);
                    }
                });

                this.setAllSectionTotals();
                this.$emit("change");
            },
            add(amount) {
                this.products.forEach(p => {
                    p.amount += amount;
                    if (p.amount < 0) p.amount = 0;

                    // for orders
                    if (this.planItems) {
                        let actual = p.rest - p.amount;
                        if (actual < 0) {
                            p.amount = p.rest;
                            actual = 0;
                        }
                        Vue.set(p, "actual", actual);
                    }
                });

                this.setAllSectionTotals();
                this.$emit("change");
            },
            sectionAmount(section) {
                //console.log("+Amount " + section.NAME);

				const prop = this.confirmed ? "confirmedAmount" : "amount";

                // calc products amounts
                const totalInChildProducts = this.itemsOfSection(section)?.reduce((t, p) => {
                    return t + (Number(p[prop]) || 0);
                }, 0) || 0;

                // calc amounts in children
                if (!section.children) {
                    //console.log("\tin products: " + totalInChildProducts);
                    return totalInChildProducts;
                }
                return totalInChildProducts + section.children.reduce((total, s) => {
                    //const totalInChildSections = this.sectionAmount(s);
                    //console.log("\tin sections: " + totalInChildSections);
                    //const totalInChildProducts = this.itemsOfSection(s)?.reduce((t, p) => t + (Number(p.amount) || 0), 0) || 0;
                    //return total + totalInChildProducts;
                    const c = s.total || 0;
                    //console.log("\t" + s.NAME + ": " + c);
                    //return total + totalInChildSections + totalInChildProducts;
                    return total + c;
                }, 0);
            },
            setSectionTotal(section) {
                //console.log("SETTING TOTAL " + section.NAME);
                Vue.set(section, "total", this.sectionAmount(section));
            },
            setAllSectionTotals() {
                //this.calcAllSectionTotals(this.tree);
                /*const calcAllSectionTotals = (sections => {
                    sections.forEach((s, index) => {
                        //console.log(s.NAME+": children: ", s.children);
                        this.setSectionTotal(s);
                        if (s.children) calcAllSectionTotals(s.children);
                    });
                });
                calcAllSectionTotals(this.tree);*/

                //console.log("SHAKING all sections");
                const shakeSectionsTotals = (sections => {
                    sections.forEach((s, index) => {
                        const items = this.itemsOfSection(s);
                        if (items?.length) this.onChange(items[0], s, false);
                        else if (s.children) shakeSectionsTotals(s.children);
                    });
                });
                shakeSectionsTotals(this.tree);

                /*const plainChildrenOf = (items)=> {
                    if ( !items ) return [];
                    return items.reduce((all, item) => {
                        if ( !item.children ) return [...all, item];
                        return [...all, item, ...plainChildrenOf(item.children)];
                    }, []);
                };
                const plainTree = plainChildrenOf(this.tree);

                let parent, s = section;
                while (!!(parent = plainTree.find(el => el.children?.find(c => c.ID === s.ID)))) {
                    console.log("SETTING TOTAL "+parent.NAME);
                    this.setSectionTotal(parent);
                    s = parent;
                }*/

            },
            itemsOfSection(section) {
                if (!this.products) return [];
                return this.products?.filter((el, index) => el.IBLOCK_SECTION_ID === section.ID);
            },
            initProductAmounts() {
                this.products?.forEach(p => {
                	const el = this.initialItems?.find(el => el.XML_ID === p.XML_ID);
                    const amount = (+el?.amount) || 0;
                    const confirmedAmount = (+el?.confirmedAmount) || 0;
                    Vue.set(p, "amount", amount);
					Vue.set(p, "confirmedAmount", confirmedAmount);

                    if (this.planItems) {
                    	const pp = this.planItems?.find(el => el.XML_ID === p.XML_ID);
                        const planAmount = (+pp?.confirmedAmount) || 0;
                        Vue.set(p, "plan", planAmount);
                        const restAmount = (+pp?.rest) || 0;
                        Vue.set(p, "rest", restAmount);
                        Vue.set(p, "ordered", planAmount - restAmount);

                        const actual = restAmount - amount;
                        Vue.set(p, "actual", actual >= 0 ? actual : 0);
                    }
                });

                this.setAllSectionTotals();
            },

        },
    }
</script>

<style lang="scss">
</style>