<template>
	<v-expansion-panels v-model="panels" multiple class="product-tree" :class="'level'+level">
		<v-expansion-panel
				v-for="(section,i) in tree"
				v-if="isNonEmptySection(section)"
				:key="i">
			<v-expansion-panel-header expand-icon="mdi-menu-down">
				<div class="d-flex justify-space-between">
					<span class="product-tree__header"
						  :class="{'root':isRootSection(section)}">{{ section.localizedName }}</span>
					<span class="mr-4 product-tree__header-total">{{ section.total | nf }}</span>
				</div>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<product-tree v-if="section.children"
							  :tree="section.children"
							  :products="products"
							  :plan-items="planItems"
							  :readonly="readonly"
							  :confirmed="confirmed"
							  :level="level+1"
							  :unfold="unfoldChildren"
							  :fold="foldChildren"
							  :name-prop="nameProp"
							  @change="onChildChange"
							  class="mb-4 product-tree__sections"/>

				<v-data-table v-if="itemsOfSection(section).length"
							  :headers="headers"
							  :items="itemsOfSection(section)"
							  :disable-sort="true"
							  :items-per-page="-1"
							  :item-class="itemClass"
							  hide-default-footer class="product-tree__products">

					<template v-slot:item.NAME="{ item }">
						{{ item.localizedName }}
						<div class="product-tree__code">{{ item.CODE }}</div>
					</template>

					<template v-slot:item.actual="{ item }">
						<span class="product-tree__actual"
							  @click="onTakeAllActual(item, section)">{{ item.actual }}</span>
					</template>

					<template v-slot:item.amount="{ item }">
						<div class="d-flex justify-end">
							<span v-if="readonly || isEmptyItem(item)">{{ item.amount }}</span>
							<span v-else class="d-flex align-center justify-space-between">
								<v-btn x-small @click="add(-10,item,section)" elevation="1">-10</v-btn>
								<v-text-field v-model="item.amount"
											  class="product-tree__input"
											  maxlength="5"
											  outlined dense hide-details filled background-color="#eef"
											  @focus="onFocus"
											  @keyup="onInput(item, section)"
											  @change="onChange(item, section)"
								/>
								<v-btn x-small @click="add(10,item,section)" elevation="1">+10</v-btn>
							</span>
						</div>
					</template>

				</v-data-table>
				<p v-else-if="!section.children && !itemsOfSection(section).length">Пусто</p>

			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
	import Vue from 'vue';
	import ProductTree from "@/components/elements/product-tree";
	import {debounce} from "lodash";
	import Config from "@/config";

	export default {
		name: "product-tree",
		props: {
			unfold: {
				type: Boolean,
				default: false
			},
			fold: {
				type: Boolean,
				default: false
			},
			tree: {
				type: Array
			},
			products: {
				type: Array
			},
			planItems: {
				type: Array
			},
			readonly: {
				type: Boolean
			},
			confirmed: {
				type: Boolean
			},
			nameProp: {
				type: String
			},
			level: {
				type: Number,
				default: 0
			}
		},
		components: {
			ProductTree
		},
		data: () => ({
			panels: [],
			unfoldChildren: false,
			foldChildren: false,
		}),
		watch: {
			unfold(value) {
				if (value) {
					this.panels = this.tree.map((el, index) => (index));
					//this.panels.splice(0,this.panels.length, this.tree.map((el, index) => (index)));
					//Vue.set(this, "panels", this.tree.map((el, index) => (index)));
					this.foldChildren = false;
					setTimeout(() => {
						this.unfoldChildren = true;
					}, 200)
				}
			},
			fold(value) {
				if (value) {
					this.unfoldChildren = false;
					this.foldChildren = true;
					this.panels.splice(0, this.panels.length);
					/*setTimeout(() => {
					}, 200)*/
				}
			},
			nameProp: {
				immediate: true,
				handler() {
					this.setLocalizedNames();
				}
			}
		},
		computed: {
			headers() {
				const headers = [
					//{text: '#', value: 'i', width: '5%'},
					//{text: 'Код', align: 'start', sortable: false, value: 'CODE', width: '15%'},
					{
						text: 'Продукт',
						align: 'start',
						sortable: false,
						value: 'NAME',
						width: this.planItems ? '55%' : '75%'
					},
				];
				if (this.planItems) {
					headers.push({text: 'План', align: 'end', value: 'plan', width: '10%'});
					if (!this.confirmed) {
						headers.push({text: 'Заказано', align: 'end', value: 'ordered', width: '10%'});
						headers.push({text: 'Доступно', align: 'end', value: 'actual', width: '10%'});
					}
				}
				headers.push({text: 'Кол-во', align: 'right', value: 'amount', width: '160px'},);
				if (this.confirmed) {
					headers.push({text: 'Утверждено', align: 'right', value: 'confirmedAmount', width: '160px'},);
				}
				/*if (!this.planItems) {
					headers.push({text: 'Всего', align: 'center', value: 'total', width: '100px'},);
				}*/
				return headers;
			},
			isRoot() {
				return !this.tree?.some((el, index) => el.IBLOCK_SECTION_ID);
			}
		},
		methods: {
			add(amount, item, section) {
				Vue.set(item, "amount", (item.amount || 0) + amount);
				this.validate(item, section);
			},
			isRootSection(section) {
				return !section.IBLOCK_SECTION_ID;
			},
			isNonEmptySection(section) {
				if (this.itemsOfSection(section).length) return true;
				if (!section.children) return false;

				// now check children sections
				for (let i = 0; i < section.children.length; i++) {
					const s = section.children[i];
					if (this.isNonEmptySection(s)) return true;
				}
				return false;
			},
			isEmptyItem(item) {
				return typeof item.rest === "number" && !item.actual && !item.amount;
			},
			itemsOfSection(section) {
				return this.products.filter((el, index) => {
					return el.IBLOCK_SECTION_ID === section.ID;
				});
			},
			itemClass(item) {
				if (this.isEmptyItem(item)) return "empty";
				if (this.confirmed && item.amount !== item.confirmedAmount) return "altered";
				if (item.amount > item.plan) return "over";
			},
			localizedSectionName(item) {
				return item["UF_" + this.nameProp] || item.NAME;
			},
			localizedName(item) {
				return item[this.nameProp] || item.NAME;
			},
			setLocalizedNames() {
				this.products?.forEach(el => Vue.set(el, "localizedName", this.localizedName(el)));
				this.tree?.forEach(el => Vue.set(el, "localizedName", this.localizedSectionName(el)));
			},
			onFocus(event) {
				//this.validateDebounced(item, section);
				event.target.select();
			},
			onInput(item, section) {
				this.validateDebounced(item, section);
			},
			onChange(item, section) {
				this.validate(item, section);
			},
			validate(item, section) {
				item.amount = parseInt(item.amount);
				if (isNaN(item.amount)) item.amount = 0;
				else if (item.amount < 0) item.amount = 0;
				//else if (typeof item.actual === "number" && item.amount > item.actual) item.amount = item.actual;

				if (typeof item.rest === "number") {
					///item.amount = item.rest;
					if (item.amount > item.rest) item.amount = item.rest;
					Vue.set(item, "actual", item.rest - item.amount);
				}

				this.$emit("change", item, section);
			},
			validateDebounced: debounce(function (item, section) {
				this.validate(item, section);
			}, Config.DEBOUNCE_INPUT_TIMEOUT),
			onChildChange(item, section) {
				this.$emit("change", item, section);
			},
			onTakeAllActual(item, section) {
				item.amount = item.rest;
				this.validate(item, section);
			}
		},
		mounted() {
			this.setLocalizedNames();
		}
	}
</script>

<style lang="scss">
	.product-tree {
		&__header {
			&.root {
				font-weight: bold;
			}
		}

		&__input.v-text-field.v-text-field--enclosed {
			margin: 0 4px;

			.v-input__slot {
				min-height: 32px !important;
			}

			input {
				text-align: right;
				width: 40px;
				padding: 2px 0;
			}
		}

		.v-data-table-header {
			th:last-child {
				padding-right: 24px;
			}
		}

		tr.over {
			background: $altered;
		}

		tr.altered {
			background: $altered;
		}

		tr.empty {
			background: $empty;
		}

		&__actual {
			cursor: pointer;
		}

		&__code {
			font-size: 10px;
			color: $gray-dark;
		}

		&.theme--light.v-expansion-panels {

			&.level1 > .v-expansion-panel {
				background-color: #add8e6;
			}

			&.level2 > .v-expansion-panel {
				.v-expansion-panel-header {
					background-color: #f0f8ff;
				}
			}
		}
	}
</style>